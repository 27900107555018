<template>
  <component :is="commonComponent" id="terms" title="Ai Novel Online">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-row">
        Welcome to AiNovelOnline.com (hereinafter referred to as &quot;the Website&quot;). Please
        read the following Terms of Service (hereinafter referred to as &quot;Terms&quot;) carefully
        before using the Website. By accessing and using the Website, you agree to comply with these
        Terms. If you do not agree with these Terms, please do not use the Website.
      </div>

      <div class="common-content-head">
        Service Overview
      </div>

      <div class="common-content-row">
        The Website utilizes advanced artificial intelligence technology to generate and provide
        novel content. Users can browse, read, and download these AI-generated novels. The Website
        also offers personalized recommendations, interactive reading, and other features to enhance
        the user experience.
      </div>

      <div class="common-content-head">
        Usage Rules
      </div>

      <div class="common-content-row">
        2.1 Lawful Use: You agree to use the Website for lawful purposes only and to comply with all
        applicable laws and regulations.
      </div>

      <div class="common-content-row">
        2.2 Prohibited Conduct: You must not:
        <br>
        <ul>
          <li>
            Post any illegal, harmful, threatening, abusive, harassing, defamatory, obscene, or
            otherwise objectionable content through the Website.
          </li>
          <li>
            Attempt to interfere with or disrupt the normal functioning of the Website, including
            but not limited to transmitting viruses, malicious code, or other harmful software.
          </li>
          <li>
            <div class="common-content-row">
              Access or attempt to access any part of the Website or its systems without
              authorization.
            </div>
          </li>
          <li>
            <div class="common-content-row">
              <strong>Intellectual Property</strong>
            </div>
          </li>
        </ul>
      </div>

      <div class="common-content-row">
        3.1 Content Ownership: All content on the Website, including but not limited to text,
        images, graphics, logos, and software, is protected by copyright, trademark, and other
        intellectual property laws. You may not copy, modify, distribute, display, or use any
        content from the Website without express written permission.
      </div>

      <div class="common-content-row">
        3.2 User-Generated Content: If you post any user-generated content on the Website, you grant
        us a non-exclusive, worldwide, royalty-free, transferable license to use, copy, modify,
        distribute, and display such content.
      </div>

      <div class="common-content-head">
        AI-Generated Content
      </div>

      <div class="common-content-row">
        4.1 Nature of Content: The novel content provided by the Website is generated by artificial
        intelligence. This content may contain inaccuracies, incompleteness, or offensive material.
        We do not guarantee the accuracy, completeness, or suitability of AI-generated content.
      </div>

      <div class="common-content-row">
        4.2 Use of Content: You understand and agree that AI-generated content is for entertainment
        and reference purposes only and should not be relied upon as professional advice or
        judgment.
      </div>

      <div class="common-content-head">
        Disclaimer
      </div>

      <div class="common-content-row">
        The Website is provided on an &quot;as is&quot; basis, without any express or implied
        warranties, including but not limited to warranties of merchantability, fitness for a
        particular purpose, and non-infringement. We do not guarantee that the Website&#39;s
        content, services, or features will be uninterrupted or error-free, or that any defects will
        be corrected.
      </div>

      <div class="common-content-head">
        Limitation of Liability
      </div>

      <div class="common-content-row">
        To the maximum extent permitted by applicable law, we shall not be liable for any direct,
        indirect, incidental, special, or consequential damages arising out of or in connection with
        your use or inability to use the Website, including but not limited to loss of profits, data
        loss, or business interruption.
      </div>

      <div class="common-content-head">
        Modifications and Termination
      </div>

      <div class="common-content-row">
        We reserve the right to modify, suspend, or terminate the Website or any part of it at any
        time without notice. We also reserve the right to modify these Terms at any time. The
        modified Terms will be effective immediately upon posting on the Website. Your continued use
        of the Website constitutes your acceptance of the modified Terms.
      </div>

      <div class="common-content-head">
        Governing Law
      </div>

      <div class="common-content-row">
        These Terms are governed by and construed in accordance with the laws of the People&#39;s
        Republic of China. You agree that any disputes arising out of or in connection with these
        Terms shall be submitted to the competent courts in the location of the Website&#39;s
        operation.
      </div>

      <div class="common-content-head">
        Contact Us
      </div>

      <div class="common-content-row">
        If you have any questions about these Terms or need further information, please contact us
        at:
      </div>

      <div class="common-content-row">
        Email: ainovelonline.support@gmail.com
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Terms of services | AiNovelOnline.com',
      meta: [
        {
          name: 'description',
          content: 'Discover a world of AI-generated novels and stories at AiNovelOnline.com. Dive into unique, imaginative tales created by advanced artificial intelligence. Perfect for readers seeking fresh and innovative content.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>